import { Suspense, useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import GeneralFooter from "./shared/components/footer/GeneralFooter";
import GeneralHeader from "./shared/components/header/GeneralHeader";
import TopMenu from './shared/components/top-menu/TopMenu';
import Home from './pages/home/Home';
import { Layout, Spin, notification, Modal, Divider } from 'antd';
import { CLEAR_CURRENT_USER, SET_CURRENT_USER, SET_DEFAULT_CURRENCY, SET_LOGIN_STATUS, SET_MAINTENANCE_LIST, SET_USER_WALLET, useAuth } from './hooks/auth/AuthContext';
import { CLEAR_NOTIFICATION, SET_CURRENCY, SET_LANGUAGE, useLayout } from './hooks/layout/LayoutContext';
import { routes, RouteInfo } from './routes';
import { FormattedMessage, useIntl } from 'react-intl';
import Cookies from 'universal-cookie';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CLAIM_RED_PACKET, GET_MAINTENANCE_LIST, GET_RED_PACKET, GET_USER_PROFILE } from './shared/gql/profile.gql';
import { AiFillCloseCircle, AiOutlineClose } from 'react-icons/ai';
import { VscChevronUp } from 'react-icons/vsc';
import Register from './pages/auth/Register';
import { GET_WALLETS } from './pages/dashboard/dashboard-bottom/components/transfer/DashboardTransfer.gql';
import { forEach } from 'lodash';
import { getDatabase, ref, child, off, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import { LOGOUT } from './pages/auth/auth.gql';
import { clearAllTokens } from './shared/helpers/set-token-cookie.helper';
import useSpinner from './hooks/layout/useSpinner';
import rewardsIcon from './assets/images/red-packet.gif';
import RewardsModal from './shared/components/modals/rewards-modal/RewardsModal';
import Promo from './pages/promo/Promo';
import StickyContainer from './shared/components/sticky-container/StickyContainer';
import { checkLocation } from './shared/helpers/general-helpers';

const cookies = new Cookies();
const { Content } = Layout;

const AppProtected = () => {
    const intl = useIntl();
    const { setLoading } = useSpinner();
    const navigate = useNavigate();
    const [collapsed, setCollaped] = useState(window.innerWidth < 769 ? true : false);
    const { authState, authDispatch } = useAuth();
    const { layoutState, layoutDispatch } = useLayout();
    const refreshTokenKey = process.env.REACT_APP_REFRESH_TOKEN || 'prod-gmf-rt';
    const [showLine, setShowLine] = useState(true);
    const [listener, setListener] = useState<any>();
    const [showLineQr, setShowLineQr] = useState(true);
    const [isLogin, setIsLogin] = useState(false);
    const location = useLocation();
    const [showReward, setShowReward] = useState(false);
    const [showRewardModal, setShowRewardModal] = useState(false);
    const [count, setCount] = useState(0);
    const [amount, setAmount] = useState(0);
    const [content, setContent] = useState('');

    const [getUserProfile, { data, error, called, refetch }] = useLazyQuery(GET_USER_PROFILE, {
        fetchPolicy: 'no-cache',
        errorPolicy: "all",
        //notifyOnNetworkStatusChange: true
    });
    const [getUserWallets, { data: walletData, called: walletCalled, refetch: walletRefetch }] = useLazyQuery(GET_WALLETS,
        { fetchPolicy: "no-cache" });
    const [getRedPacket, { data: redPacketData, called: redPacketCalled, refetch: redPacketRefetch }] =
        useLazyQuery(GET_RED_PACKET, {
            fetchPolicy: "cache-and-network", pollInterval: 300000,
            onCompleted: () => setCount(count => count + 1)
        });
    const { data: maintenanceData, error: maintenanceError, called: maintenanceCalled, refetch: maintenanceRefetch } = useQuery(GET_MAINTENANCE_LIST, {
        fetchPolicy: 'no-cache', pollInterval: 30000
    });
    const [logout, { data: logoutData, loading: logoutLoading }] = useMutation(LOGOUT, { fetchPolicy: "no-cache", errorPolicy: "all" });
    const [claimRedPacket, { data: claimRedPacketData, loading: claimRedPacketLoading }] = useMutation(CLAIM_RED_PACKET, { fetchPolicy: "no-cache" });

    const onFocus = (isUserLogin: boolean) => {
        if (document.visibilityState === 'visible' && isUserLogin === true) {
            getUserWallets();
        }
    };

    const domainCurrency = async () => {
        const currencyFromStorage = localStorage.getItem('pfxc');

        if (currencyFromStorage && currencyFromStorage !== "undefined") {
            authDispatch({ type: SET_DEFAULT_CURRENCY, payload: currencyFromStorage });
            layoutDispatch({ type: SET_CURRENCY, payload: currencyFromStorage });
        } else {
            let currency = 'SGD';
            // if (window.location.hostname === 'localhost') {
            //     currency = 'SGD';
            // }

            const loc: string = await checkLocation();
            if (loc === "SG") {
                currency = 'SGD';
                setDefaultLanguage(currency);
            } else if (loc === "TH") {
                currency = 'THB';
                setDefaultLanguage(currency);
            } else if (loc === 'PH') {
                currency = 'PHP';
                setDefaultLanguage(currency);
            }
            else {
                if (window.location.hostname === 'ib8sgd.co' || window.location.hostname === 'www.ib8sgd.co'
                    || window.location.hostname === 'ib8sgd.com' || window.location.hostname === 'www.ib8sgd.com'
                    || window.location.hostname === 'ib8.site' || window.location.hostname === 'www.ib8.site'
                    || window.location.hostname === 'ib8.group' || window.location.hostname === 'www.ib8.group'
                    || window.location.hostname === 'ib8.info' || window.location.hostname === 'www.ib8.info'
                    || window.location.hostname === 'ib8sgd.net' || window.location.hostname === 'www.ib8sgd.net'
                    || window.location.hostname === 'ib8.international' || window.location.hostname === 'www.ib8.international'
                    || window.location.hostname === 'ib8.world' || window.location.hostname === 'www.ib8.world') {
                    currency = 'SGD';
                } else if (window.location.hostname === 'ib8ph.com' || window.location.hostname === 'ib8ph.co'
                    || window.location.hostname === "ib8ph.net" || window.location.hostname === "ib8ph.bet") {
                    currency = 'PHP';
                }
            }
            authDispatch({ type: SET_DEFAULT_CURRENCY, payload: currency });
            layoutDispatch({ type: SET_CURRENCY, payload: currency });
            localStorage.setItem('pfxc', currency);
            setDefaultLanguage(currency);
        }
    };

    const setDefaultLanguage = (currency: string) => {
        if (currency === 'THB') {
            localStorage.setItem('pfxl', 'th');
            layoutDispatch({ type: SET_LANGUAGE, payload: 'th' });
        } else if (currency === 'SGD') {
            localStorage.setItem('pfxl', 'en');
            layoutDispatch({ type: SET_LANGUAGE, payload: 'en' });
        }
    }

    useEffect(() => {
        if (logoutLoading || claimRedPacketLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutLoading, claimRedPacketLoading])

    useEffect(() => {
        //document.addEventListener('visibilitychange', ()=>{onFocus(isLogin)});
        // Calls onFocus when the window first loads
        onFocus(isLogin);
        //maintenanceCalled && maintenanceRefetch ? maintenanceRefetch() : getMaintenanceList();
    }, []);

    useEffect(() => {
        if (location.pathname !== "/home/dashboard") {
            localStorage.setItem("dashboardTab", '1');
        }
    }, [location.pathname])

    useEffect(() => {
        if (walletData && walletData.wallets && walletData.wallets.wallets) {
            let walletList: any = [];
            let totalWalletBalance = 0;

            forEach(walletData.wallets.wallets, (wallet) => {
                totalWalletBalance += +wallet.balance;
                walletList.push({
                    name: wallet.walletType,
                    value: wallet.id,
                    balance: wallet.balance,
                    userWalletType: wallet.userWalletType,
                    isMaintenance: wallet.isMaintenance,
                    isBonus: wallet.isBonus
                });
            });

            let categoryList = walletData.wallets.walletCategory;

            let bonusWalletType: any = [];

            if (walletData && walletData.wallets && walletData.wallets.userBonusTracker) {
                bonusWalletType = walletData.wallets.userBonusTracker.userDepositBonus?.bonusSetting?.BonusSettingWalletType;
            }

            authDispatch({ type: SET_USER_WALLET, payload: { wallets: walletList, walletCategory: categoryList, totalBalance: +totalWalletBalance, bonusWalletType: bonusWalletType } });
        }
    }, [walletData])

    useEffect(() => {
        if (authState.isLogin && layoutState.currency !== authState?.defaultCurrency) {
            localStorage.setItem('pfxl', 'en');
            layoutDispatch({ type: SET_LANGUAGE, payload: 'en' })
        }
    }, [authState.isLogin])

    useEffect(() => {
        if (authState.isLogin) {
            setIsLogin(true);
            document.addEventListener('visibilitychange', () => { onFocus(true) });
            called && refetch ? refetch() : getUserProfile();
            walletCalled && walletRefetch ? walletRefetch() : getUserWallets();
            redPacketCalled && redPacketRefetch ? redPacketRefetch() : getRedPacket()
            //maintenanceCalled && maintenanceRefetch ? maintenanceRefetch() : getMaintenanceList();
        } else {
            if (listener) {
                off(listener);
            }
            domainCurrency();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState.isLogin]);

    useEffect(() => {
        if (authState.isLogin && layoutState.currency !== authState?.defaultCurrency) {
            localStorage.setItem('pfxl', 'en');
            layoutDispatch({ type: SET_LANGUAGE, payload: 'en' })
        }
    }, [authState?.defaultCurrency])

    const toggleSideNav = () => {
        setCollaped(!collapsed);
    };

    useEffect(() => {
        if (logoutData && logoutData.logout) {
            clearAllTokens();
            authDispatch({ type: CLEAR_CURRENT_USER });
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
            navigate('/home');
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logoutData])

    //Set user profile is profile data changed
    useEffect(() => {
        if (data && !error) {
            authDispatch({ type: SET_CURRENT_USER, payload: data });
            localStorage.setItem('pfxc', data?.userProfile?.currency);
            authDispatch({ type: SET_DEFAULT_CURRENCY, payload: data?.userProfile?.currency });
            layoutDispatch({ type: SET_CURRENCY, payload: data?.userProfile?.currency });

            //firebase
            if (data?.userProfile && data?.userProfile?.id && authState.isLogin) {
                localStorage.setItem("ua", data?.userProfile?.ua);
                const firebaseConfig = {
                    databaseURL: "https://ib8web-default-rtdb.asia-southeast1.firebasedatabase.app",
                };
                const app = initializeApp(firebaseConfig);
                const db = getDatabase(app);
                const listener = ref(db, process.env.REACT_APP_ENV + data?.userProfile?.id);
                setListener(listener);
                onValue(listener, (snapshot) => {
                    const firebase = snapshot.val();
                    const newUa = localStorage.getItem("ua");
                    if (newUa && firebase.ua) {
                        if (firebase.ua.toString() != newUa) {
                            localStorage.removeItem("ua");
                            off(listener);
                            logout();
                        }
                    }
                });
            }
        } else if (error) {
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    useEffect(() => {
        if (redPacketData) {
            if (redPacketData?.bonusesRedPacket && redPacketData?.bonusesRedPacket?.id) {
                setShowReward(true);
            } else {
                setShowReward(false);
            }  // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, [redPacketData, count]);

    useEffect(() => {
        if (claimRedPacketData) {
            if (claimRedPacketData?.updateRedPacketBonus && claimRedPacketData?.updateRedPacketBonus?.status === 'SUCCESS') {
                setAmount(+(+claimRedPacketData?.updateRedPacketBonus?.a).toFixed(2));
                setShowRewardModal(true);
                setContent(claimRedPacketData?.updateRedPacketBonus?.id);
            } // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, [claimRedPacketData]);

    useEffect(() => {
        if (maintenanceData && !maintenanceError) {
            authDispatch({ type: SET_MAINTENANCE_LIST, payload: maintenanceData?.maintenanceList?.providerIdList ?? null });
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maintenanceData, maintenanceError]);

    useEffect(() => {
        if (layoutState.showNotification && layoutState.showNotification.message) {
            openNotificationWithIcon();
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutState.showNotification])

    useEffect(() => {
        if (layoutState.showModal && layoutState.showModal.message) {
            switch (layoutState?.showModal.type) {
                case "success":
                    Modal.success({
                        title: layoutState?.showModal?.title,
                        content: layoutState?.showModal?.description,
                    });
                    break;
                case "error":
                    Modal.error({
                        title: layoutState?.showModal?.title,
                        content: layoutState?.showModal?.description,
                    });
                    break;
                case "warning":
                    Modal.warning({
                        title: layoutState?.showModal?.title,
                        content: layoutState?.showModal?.description,
                    });
                    break;
                case "info":
                default:
                    Modal.info({
                        title: layoutState?.showModal?.title,
                        content: layoutState?.showModal?.description,
                    });
                    break;
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layoutState.showModal])

    useEffect(() => {
        if (!cookies.get(refreshTokenKey) && authState.isLogin) {
            authDispatch({ type: SET_LOGIN_STATUS, payload: false });
            navigate('/auth', { replace: true })
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies.get(refreshTokenKey)]);

    const openNotificationWithIcon = () => {
        notification[layoutState?.showNotification?.type]({
            message: layoutState?.showNotification.message,
            description: layoutState?.showNotification?.description,
        });
        layoutDispatch({ type: CLEAR_NOTIFICATION })
    };

    const protectedRoutesAccessCheck = (route: RouteInfo) => {
        return (<Suspense fallback={<Home />}>
            <route.component routes={route.routes} />
        </Suspense>);
    }

    const onLineClick = () => {
        window.open("https://liff.line.me/1645278921-kWRPP32q/?accountId=734uafov");
    }

    const onMailClick = () => {
        window.open("mailto:player.engagement@ib8thb.com");
    }

    const onScrollTopClick = () => {
        document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const onRewardsClick = () => {
        claimRedPacket();
    }

    const onRewardsModalClose = () => {
        setShowRewardModal(false);
        setShowReward(false);
    }

    return (<Spin tip={intl.formatMessage({ id: "SPINNER_TIP" })} spinning={layoutState.showLoading}>
        <Layout className="app-container">
            <Layout className="site-layout">
                {//authState.isLogin &&
                    <GeneralHeader />
                }
                {//authState.isLogin &&
                    <TopMenu />
                }
                <Content
                    className="site-layout-background">
                    {/* {authState?.defaultCurrency === 'THB' ?
                        <>
                            <SideStickyButton />
                            <StickyQR className={'line'} isTopLogo={false} qrTxtImg={LineText} qrImg={LineQR} qrLink={thbLineQrLink} hasBottomLink={true} />
                        </>
                        :
                        <>
                            <StickyQR className={'telegram'} isTopLogo={true} qrTxtImg={telegramQRLogo} qrImg={telegramQR} qrLink={sgdTelegramQrLink} hasBottomLink={false} />
                            <StickyQR className={'whatsapp'} isTopLogo={true} qrTxtImg={whatsAppQRLogo} qrImg={whatsAppQR} qrLink={sgdWhatsAppQrLink} hasBottomLink={false} />
                        </>
                    } */}
                    <StickyContainer />
                    <div className='scroll-top-sticky-btn' onClick={onScrollTopClick}>
                        <VscChevronUp />
                    </div>
                    <div className={showReward ? "reward-btn-stick " : "reward-btn-stick  hide"}>
                        <img className='reward-img' src={rewardsIcon} onClick={onRewardsClick} />
                    </div>
                    <Routes>
                        <Route path='' element={<Home />} />
                        <Route path="referral/*" element={
                            <Suspense fallback={<Spin tip={intl.formatMessage({ id: "SPINNER_TIP" })} />}>
                                <Register />
                            </Suspense>
                        } />
                        <Route path="promotions/referral/*" element={
                            <Suspense fallback={<Spin tip={intl.formatMessage({ id: "SPINNER_TIP" })} />}>
                                <Promo />
                            </Suspense>
                        } />
                        {routes.map((route: RouteInfo, i) => {
                            return (
                                <Route key={i} path={`${route.path}`} element={
                                    protectedRoutesAccessCheck(route)
                                } />
                            );
                        })}
                    </Routes>
                    <RewardsModal
                        detailContainerName={'rewards-detail-container'}
                        modalVisible={showRewardModal}
                        onClose={onRewardsModalClose}
                        name={authState.userProfile?.name}
                        currency={authState.userProfile?.currency}
                        amount={amount}
                        content={content} />

                    {//authState.isLogin &&
                        <GeneralFooter />
                    }
                </Content>
            </Layout>
        </Layout>
    </Spin >);
}

export default AppProtected;