import SportsUfa from '../../../assets/images/menu-sports-01.png';
import SportsUfaLogo from '../../../assets/images/logo-ib8sports-white.png';
import SportsUfaLogoAlt from '../../../assets/images/logo-ib8sports.png';
import SportsUfaMobileLogo from '../../../assets/images/logo-ib8sports.png';
import SportsCmd from '../../../assets/images/menu-sports-03.png';
import SportsCmdLogo from '../../../assets/images/logo-cmd.png';
import SportsSaba from '../../../assets/images/menu-sports-02.png';
import SportsSabaLogo from '../../../assets/images/logo-saba-white.png';
import SportsSabaLogoAlt from '../../../assets/images/logo-saba.png';
import SportsSabaMobileLogo from '../../../assets/images/logo-saba.png';
import SportsM8Sport from '../../../assets/images/menu-sports-04.png';
import SportsM8SportLogo from '../../../assets/images/logo-m8bet.png';
// import SportsUfaLogoAlt from '../../../assets/images/logo-ib8sports.png';
// import SportsUfaMobileLogo from '../../../assets/images/logo-ib8sports.png';
import ESportsIM from '../../../assets/images/menu-esports-01.png';
import ESportsIMLogo from '../../../assets/images/logo-im.png';
import ESportsTFG from '../../../assets/images/menu-esports-02.png';
import ESportsTFGLogo from '../../../assets/images/logo-tfgaming.png';
import CasinoSexy from '../../../assets/images/menu-casino-b.png';
import CasinoSexyLogo from '../../../assets/images/logo-sexy.png';
import CasinoDream from '../../../assets/images/menu-casino-02.png';
import CasinoDreamLogo from '../../../assets/images/logo-dreamgaming.png';
import CasinoSA from '../../../assets/images/menu-casino-a.png';
import CasinoSAAlt from '../../../assets/images/logo-sagaming.png';
import CasinoSALogo from '../../../assets/images/logo-sagaming-white.png';
import CasinoEvo from '../../../assets/images/menu-casino-c.png';
import CasinoEvoLogo from '../../../assets/images/logo-evolution-white.png';
import CasinoEvoLogoAlt from '../../../assets/images/logo-evolution.png';
import CasinoBbinLogo from '../../../assets/images/logo-bbin.png';
import CasinoBbin from '../../../assets/images/menu-casino-j.png';
import CasinoBG from '../../../assets/images/menu-casino-05.png';
import CasinoBGLogo from '../../../assets/images/logo-big-gaming.png';
import CasinoOPUS from '../../../assets/images/menu-casino-h.png';
import CasinoOPUSLogo from '../../../assets/images/logo-opus.png';
import CasinoOPUSWhiteLogo from '../../../assets/images/logo-opus-white.png';
import CasinoYEEBET from '../../../assets/images/menu-casino-d.png';
import CasinoYEEBETLogo from '../../../assets/images/logo-yeebet.png';
import CasinoVMCASINO from '../../../assets/images/menu-casino-f.png';
import CasinoVMCASINOLogo from '../../../assets/images/logo-wm.png';
import CasinoROYALCASINO from '../../../assets/images/menu-casino-g.png';
import CasinoROYALLogo from '../../../assets/images/logo-royal.png';
import CasinoPPCASINO from '../../../assets/images/menu-casino-i.png';
import CasinoPPLogo from '../../../assets/images/logo-pragmaticplay-white.png';
import CasinoEzugiCASINO from '../../../assets/images/menu-casino-e.png';
import CasinoEzugiLogo from '../../../assets/images/logo-ezugi.png';
import CasinoBeterLogo from '../../../assets/images/logo-beter.png';
import CasinoBeter from '../../../assets/images/menu-casino-k.png';
import SlotsPG from '../../../assets/images/menu-slots-a.png';
import SlotsPGBlackLogo from '../../../assets/images/logo-pg.png';
import SlotsPGLogo from '../../../assets/images/logo-pg-white.png';
import SlotsPP from '../../../assets/images/menu-slots-b.png';
import SlotsPPLogo from '../../../assets/images/logo-pragmaticplay-white.png';
import SlotsMGS from '../../../assets/images/menu-slots-02.png';
import SlotsMGSLogo from '../../../assets/images/logo-mgs.png';
import SlotsJoker from '../../../assets/images/menu-slots-d.png';
import SlotsJokerLogo from '../../../assets/images/logo-joker.png';
import SlotsCQ9 from '../../../assets/images/menu-slots-e.png';
import SlotsCQ9Logo from '../../../assets/images/logo-cq9.png';
import SlotsDG from '../../../assets/images/menu-slots-05.png';
import SlotsDGLogo from '../../../assets/images/logo-dragongaming.png';
import SlotsSG from '../../../assets/images/menu-slots-c.png';
import SlotsSGLogo from '../../../assets/images/logo-spadegaming.png';
import SlotsJL from '../../../assets/images/menu-slots-f.png';
import SlotsJLLogo from '../../../assets/images/logo-jili.png';
import SlotsFastSpin from '../../../assets/images/menu-slots-fa.png';
import SlotsFastSpinLogo from '../../../assets/images/logo-fastspin.png';
import SlotsSpribe from '../../../assets/images/menu-slots-fc.png';
import SlotsSpribeLogo from '../../../assets/images/logo-spribe.png';
import SlotsFacai from '../../../assets/images/menu-slots-fb.png';
import SlotsFacaiLogo from '../../../assets/images/logo-fachai.png';
import SlotsNetent from '../../../assets/images/menu-slots-fd.png';
import SlotsNetentLogo from '../../../assets/images/logo-netent.png';
import SlotsNoLimit from '../../../assets/images/menu-slots-fe.png';
import SlotsNoLimitLogo from '../../../assets/images/logo-nolimitcity.png';
import SlotsRedTiger from '../../../assets/images/menu-slots-ff.png';
import SlotsRedTigerLogo from '../../../assets/images/logo-redtiger.png';
import SlotsBigTimeGaming from '../../../assets/images/menu-slots-fg.png';
import SlotsBigTimeGamingLogo from '../../../assets/images/logo-bigtimegaming.png';
import SlotsT1 from '../../../assets/images/menu-slots-fj.png';
import SlotsT1Logo from '../../../assets/images/logo-t1games.png';
import SlotsMega from '../../../assets/images/menu-virtualgames-02.png';
import SlotsMegaLogo from '../../../assets/images/logo-mega888.png';
import MenuPP from '../../../assets/images/menu-virtualgames-01.png';
import MenuPPLogo from '../../../assets/images/logo-kingmaker.png';
import MenuMP from '../../../assets/images/menu-virtualgames-03.png';
import MenuMPLogo from '../../../assets/images/logo-mp.png';
import MenuMega from '../../../assets/images/menu-virtualgames-02.png';
import MenuMegaLogo from '../../../assets/images/logo-mega888.png';
import FishingPP from '../../../assets/images/menu-fishing-01.png';
import FishingBG from '../../../assets/images/menu-fishing-02.png';
import FishingCQ9 from '../../../assets/images/menu-fishing-03.png';
import FishJLLogo from '../../../assets/images/logo-jili-fishing.png';
import FishSGLogo from '../../../assets/images/menu-fishing-04.png'
import FishJOKERLogo from '../../../assets/images/menu-fishing-05.png';
import LotteryQQKeno from '../../../assets/images/menu-lottery-01.png';
import LotteryQQKenoLogo from '../../../assets/images/logo-qqkeno-white.png';
import LotteryQQKenoMobileLogo from '../../../assets/images/logo-qqkeno.png';
import LotteryQQLottery from '../../../assets/images/menu-lottery-02.png';
import LotteryQQLotteryLogo from '../../../assets/images/logo-qqlottery-white.png';
import LotteryQQLotteryMobileLogo from '../../../assets/images/logo-qqlottery.png';
import LotteryAELottery from '../../../assets/images/menu-lottery-03.png';
import LotteryAELotteryLogo from '../../../assets/images/logo-aelottery.png';
import HorseBook from '../../../assets/images/menu-horsing-01.png';
import HorseBookLogo from '../../../assets/images/logo-rcb988-white.png';
import GA28 from '../../../assets/images/ga28-logo2.png';
import GA28Logo from '../../../assets/images/logo-ga28.png';
import GA28MobileLogo from '../../../assets/images/logo-ga28-mb.png';

import SlotsPPImg from '../../../assets/images/logo-pragmaticplay.png';
import SlotsDGImg from '../../../assets/images/logo-dragongaming.png';
import SlotsCQ9Img from '../../../assets/images/logo-cq9.png';
import SlotsJokerImg from '../../../assets/images/logo-joker.png';
import SlotsMGSImg from '../../../assets/images/logo-mgs.png';
import SlotsSGImg from '../../../assets/images/logo-spadegaming.png';

import SportsLandingPageBanner from '../../../assets/images/banner-sports.jpg';
import ESportsLandingPageBanner from '../../../assets/images/banner-esports.jpg';
import CasinoLandingPageBanner from '../../../assets/images/banner-casino.jpg';
import SlotsLandingPageBanner from '../../../assets/images/banner-slots.jpg';
import VGamesLandingPageBanner from '../../../assets/images/banner-virtualgames.jpg';
import FishingLandingPageBanner from '../../../assets/images/banner-fishing.jpg';
import LotteryLandingPageBanner from '../../../assets/images/banner-lottery.jpg';
import InstantWinLandingPageBanner from '../../../assets/images/banner-slots.jpg';

import SlotsCardImg from '../../../assets/images/categories-slots.png';
import CasinoCardImg from '../../../assets/images/categories-casino.png';
import EsportsCardImg from '../../../assets/images/categories-esports.png';
import LotteryCardImg from '../../../assets/images/categories-lottery.png';
import SportsCardImg from '../../../assets/images/categories-sports.png';
import VirtualGamesCardImg from '../../../assets/images/categories-virtualgames.png';
import InstantWinCardImg from '../../../assets/images/categories-slots.png';

import HotGames from '../../../assets/images/hotgames-01.jpg';
import HotGames2 from '../../../assets/images/hotgames-02.jpg';
import HotGames3 from '../../../assets/images/hotgames-03.jpg';

import CountryThai from '../../../assets/images/language-thailand.png';
import CountrySg from '../../../assets/images/language-singapore.png';
import CountryPh from '../../../assets/images/language-philippines.png';

import MobileGameMenuSports from '../../../assets/images/menu-icon-sports.svg';
import MobileGameMenuSportsSelected from '../../../assets/images/menu-icon-sports-grey.svg';
import MobileGameMenuESports from '../../../assets/images/menu-icon-esports.svg';
import MobileGameMenuESportsSelected from '../../../assets/images/menu-icon-esports-grey.svg';
import MobileGameMenuCasino from '../../../assets/images/menu-icon-casino.svg';
import MobileGameMenuCasinoSelected from '../../../assets/images/menu-icon-casino-grey.svg';
import MobileGameMenuSlots from '../../../assets/images/menu-icon-slots.svg';
import MobileGameMenuSlotsSelected from '../../../assets/images/menu-icon-slots-grey.svg';
import MobileGameMenuHorse from '../../../assets/images/menu-icon-horseracing.svg';
import MobileGameMenuHorseSelected from '../../../assets/images/menu-icon-horseracing-grey.svg';
import MobileGameMenuCockFight from '../../../assets/images/menu-icon-cockfight.svg';
import MobileGameMenuCockFightSelected from '../../../assets/images/menu-icon-cockfight-grey.svg';
import MobileGameMenuVG from '../../../assets/images/menu-icon-virtualgames.svg';
import MobileGameMenuVGSelected from '../../../assets/images/menu-icon-virtualgames-grey.svg';
import MobileGameMenuFish from '../../../assets/images/menu-icon-fishing.svg';
import MobileGameMenuFishSelected from '../../../assets/images/menu-icon-fishing-grey.svg';
import MobileGameMenuLottery from '../../../assets/images/menu-icon-lottery.svg';
import MobileGameMenuLotterySelected from '../../../assets/images/menu-icon-lottery-grey.svg';
import MobileGameMenuInstantWin from '../../../assets/images/menu-icon-instantwin.svg';
import MobileGameMenuInstantWinSelected from '../../../assets/images/menu-icon-instantwin-grey.svg';

import MSportsUfaBgImg from '../../../assets/images/menu-sports-mb-01.jpg';
import MSportsSabaBgImg from '../../../assets/images/menu-sports-mb-02.jpg';
import MSportsCmdBgImg from '../../../assets/images/menu-sports-mb-03.jpg';

import sportsThumb1 from '../../../assets/images/thumbnails-nav-15.png';
import sportsThumb2 from '../../../assets/images/thumbnails-nav-16.png';
import sportsThumb3 from '../../../assets/images/thumbnails-nav-17.png';
import sportsThumb4 from '../../../assets/images/thumbnails-nav-23.png';

import sports1 from '../../../assets/images/section-sports-itema.png';
import sports2 from '../../../assets/images/section-sports-itemb.png';
import sports3 from '../../../assets/images/section-sports-itemc.png';
import sports4 from '../../../assets/images/section-sports-itemd.png';

import casinoLandingBannerSA from '../../../assets/images/section-casino-item1.png';
import casinoLandingBannerSexy from '../../../assets/images/section-casino-item2.png';
import casinoLandingBannerEvo from '../../../assets/images/section-casino-item3.png';
import casinoLandingBannerYeeBet from '../../../assets/images/section-casino-item4.png';
import casinoLandingBannerOpus from '../../../assets/images/section-casino-item8.png';
import casinoLandingBannerWM from '../../../assets/images/section-casino-item6.png';
import casinoLandingBannerRoyal from '../../../assets/images/section-casino-item7.png';
import casinoLandingBannerPP from '../../../assets/images/section-casino-item9.png';
import casinoLandingBannerBbin from '../../../assets/images/section-casino-item10.png';
import casinoLandingBannerBeter from '../../../assets/images/section-casino-item11.png';
import casinoLandingBannerEzugi from '../../../assets/images/section-casino-item5.png';
import esportsLandingBannerTF from '../../../assets/images/section-esports-item1.png';
import sportsLandingBannerSaba from '../../../assets/images/section-sports-itema.png';
import sportsLandingBannerCmd from '../../../assets/images/section-sports-itemb.png';
import sportsLandingBannerUfa from '../../../assets/images/section-sports-itemc.png';
import horseLandingBanner from '../../../assets/images/section-horsing-item1.png';
import lottoLandingBannerKm from '../../../assets/images/section-lottery-item1.png';
import lottoLandingBannerQq from '../../../assets/images/section-lottery-item2.png';
import virtualLandingBannerKm from '../../../assets/images/section-virtual-item1.png';
import virtualLandingBannerMp from '../../../assets/images/section-virtual-item2.png';
import ga28LandingBanner from '../../../assets/images/section-cockfight-item1.png';

import casinoLandingThumbnailSA from '../../../assets/images/thumbnails-nav-9.png';
import casinoLandingThumbnailSexy from '../../../assets/images/thumbnails-nav-10.png';
import casinoLandingThumbnailEvo from '../../../assets/images/thumbnails-nav-11.png';
import casinoLandingThumbnailYB from '../../../assets/images/thumbnails-nav-12.png';
import casinoLandingThumbnailWM from '../../../assets/images/thumbnails-nav-14.png';
import casinoLandingThumbnailRoyal from '../../../assets/images/thumbnails-nav-18.png';
import casinoLandingThumbnailOpus from '../../../assets/images/thumbnails-nav-21.png';
import casinoLandingThumbnailPP from '../../../assets/images/thumbnails-nav-22.png';
import casinoLandingThumbnailEzugi from '../../../assets/images/thumbnails-nav-13.png';
import casinoLandingThumbnailBbin from '../../../assets/images/thumbnails-nav-24.png';
import casinoLandingThumbnailBeter from '../../../assets/images/thumbnails-nav-27.png';
import esportsLandingThumbnailTF from '../../../assets/images/thumbnails-nav-4.png';
import sportsLandingThumbnailSaba from '../../../assets/images/thumbnails-nav-15.png';
import sportsLandingThumbnailCmd from '../../../assets/images/thumbnails-nav-16.png';
import sportsLandingThumbnailUfa from '../../../assets/images/thumbnails-nav-17.png';
import horseLandingThumbnail from '../../../assets/images/thumbnails-nav-5.png';
import lottoLandingThumbnailKm from '../../../assets/images/thumbnails-nav-6.png';
import lottoLandingThumbnailQq from '../../../assets/images/thumbnails-nav-7.png';
import virtualLandingThumbnailKm from '../../../assets/images/thumbnails-nav-19.png';
import virtualLandingThumbnailMp from '../../../assets/images/thumbnails-nav-20.png';
import ga28Thumbnail from '../../../assets/images/thumbnails-nav-29.png';

import sportLandingFloatingImg1 from '../../../assets/images/flying-ribbon-1.png';
import sportLandingFloatingImg2 from '../../../assets/images/flying-ribbon-2.png';
import esportLandingFloatingImg1 from '../../../assets/images/magic-ball-1.png';
import esportLandingFloatingImg2 from '../../../assets/images/magic-ball-2.png';
import casinoLandingFloatingImg1 from '../../../assets/images/flying-chips-1.png';
import casinoLandingFloatingImg2 from '../../../assets/images/flying-chips-2.png';
import horseLandingFloatingImg1 from '../../../assets/images/flying-grass-1.png';
import horseLandingFloatingImg2 from '../../../assets/images/flying-grass-2.png';
import lottoLandingFloatingImg1 from '../../../assets/images/flying-star-1.png';
import lottoLandingFloatingImg2 from '../../../assets/images/flying-star-2.png';
import virtualLandingFloatingImg1 from '../../../assets/images/flying-poker-1.png';
import virtualLandingFloatingImg2 from '../../../assets/images/flying-poker-2.png';
import ga28LandingFloatingImg1 from '../../../assets/images/flying-sand-1.png';
import ga28LandingFloatingImg2 from '../../../assets/images/flying-sand-2.png';

import landingIconPG from '../../../assets/images/icon-pg.png';
import landingIconPP from '../../../assets/images/icon-pragmaticplay.png';
import landingIconJoker from '../../../assets/images/icon-joker.png';
import landingIconSG from '../../../assets/images/icon-spadegaming.png';
import landingIconCQ9 from '../../../assets/images/icon-cq9.png';
import landingIconJili from '../../../assets/images/icon-jili.png';
import landingIconFastSpin from '../../../assets/images/icon-fastspin.png';
import landingIconSpribe from '../../../assets/images/icon-spribe.png';
import landingIconFacai from '../../../assets/images/icon-fachai.png';
import landingIconT1 from '../../../assets/images/icon-t1games.png';
import landingIconNetent from '../../../assets/images/icon-netent.png';
import landingIconNoLimit from '../../../assets/images/icon-nolimitcity.png';
import landingIconRedTiger from '../../../assets/images/icon-redtiger.png';
import landingIconBigTimeGaming from '../../../assets/images/icon-bigtimegaming.png';

export const gamesProviderLeft = [
    {
        gameType: "sports",
        label: 'MENU_SPORTSBOOK',
        landingPageBanner: SportsLandingPageBanner,
        homeCardImg: SportsCardImg,
        mobileMenuImg: MobileGameMenuSports,
        mobileMenuImgSelected: MobileGameMenuSportsSelected,
        mobileGameListType: 1,
        currency: ['THB', 'SGD', 'PHP'],
        games: [
            {
                id: "sportssaba",
                urlLabel: 'saba',
                label: "SABA",
                image: SportsSaba,
                mobileBgClass: 'saba-bg',
                logo: SportsSabaLogo,
                logoAlt: SportsSabaLogoAlt,
                mobileLogo: SportsSabaMobileLogo,
                url: "SABA",
                hasUrl: true,
                gameCode: "",
                providerId: 'SABA', //for maintenance checking,
                specialTag: '',
                currency: ['THB'],
                thumbnail: sportsThumb1,
                sportsSectionImg: sports1,
                landingBanner: sportsLandingBannerSaba,
                landingBannerClassName: 'landing-banner-saba',
                landingBannerDesc: 'LANDING_BANNER_DESC_SABA',
                landingThumbnail: sportsLandingThumbnailSaba,
                floatingImg: [sportLandingFloatingImg1, sportLandingFloatingImg2]
            },
            {
                id: "sportscmd",
                urlLabel: 'cmd368',
                label: "CMD",
                image: SportsCmd,
                mobileBgClass: 'cmd-bg',
                logo: SportsCmdLogo,
                logoAlt: SportsCmdLogo,
                url: "CMD",
                hasUrl: true,
                gameCode: "",
                providerId: 'CMD', //for maintenance checking,
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                thumbnail: sportsThumb2,
                sportsSectionImg: sports2,
                landingBanner: sportsLandingBannerCmd,
                landingBannerClassName: 'landing-banner-cmd',
                landingBannerDesc: 'LANDING_BANNER_DESC_CMD',
                landingThumbnail: sportsLandingThumbnailCmd,
                floatingImg: [sportLandingFloatingImg1, sportLandingFloatingImg2]
            },
            {
                id: "sportsufa",
                urlLabel: 'ufa',
                label: "UFA",
                image: SportsUfa,
                mobileBgClass: 'ufa-bg',
                logo: SportsUfaLogo,
                logoAlt: SportsUfaLogoAlt,
                mobileLogo: SportsUfaMobileLogo,
                url: "OPUS",
                hasUrl: true,
                gameCode: "",
                providerId: 'OPUS', //for maintenance checking,
                specialTag: '',
                currency: ['THB'],
                thumbnail: sportsThumb3,
                sportsSectionImg: sports3,
                landingBanner: sportsLandingBannerUfa,
                landingBannerClassName: 'landing-banner-ufa',
                landingBannerDesc: 'LANDING_BANNER_DESC_UFA',
                landingThumbnail: sportsLandingThumbnailUfa,
                floatingImg: [sportLandingFloatingImg1, sportLandingFloatingImg2]
            },
            {
                id: "sportm8",
                urlLabel: 'm8sport',
                label: "M8Sport",
                image: SportsM8Sport,
                mobileBgClass: 'm8-bg',
                logo: SportsM8SportLogo,
                logoAlt: SportsUfaLogoAlt,
                mobileLogo: SportsM8SportLogo,
                url: "M8",
                hasUrl: true,
                gameCode: "",
                providerId: 'M8', //for maintenance checking,
                specialTag: '',
                currency: ['SGD', 'PHP'],
                thumbnail: sportsThumb4,
                sportsSectionImg: sports4,
                landingBanner: sports4,
                landingBannerClassName: 'landing-banner-ufa',
                landingBannerDesc: 'LANDING_BANNER_DESC_M8',
                landingThumbnail: sportsThumb4,
                floatingImg: [sportLandingFloatingImg1, sportLandingFloatingImg2]
            },
        ],
    },
    {
        gameType: "casino",
        label: 'MENU_CASINO',
        landingPageBanner: CasinoLandingPageBanner,
        homeCardImg: CasinoCardImg,
        mobileMenuImg: MobileGameMenuCasino,
        mobileMenuImgSelected: MobileGameMenuCasinoSelected,
        mobileGameListType: 2,
        currency: ['THB', 'SGD', 'PHP'],
        games: [
            {
                id: "casinoevo",
                urlLabel: 'evolution-gaming',
                label: "EVOLUTION",
                image: CasinoEvo,
                mobileBgClass: 'evo-bg',
                logo: CasinoEvoLogo,
                logoAlt: CasinoEvoLogoAlt,
                url: "EVOLUTION",
                hasUrl: true,
                gameCode: "",
                providerId: 'EVOLUTION', //for maintenance checking,
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingBanner: casinoLandingBannerEvo,
                landingBannerClassName: 'landing-banner-evo',
                landingBannerDesc: 'LANDING_BANNER_DESC_EVO',
                landingThumbnail: casinoLandingThumbnailEvo,
                floatingImg: [casinoLandingFloatingImg1, casinoLandingFloatingImg2]
            },
            {
                id: "casinoezugi",
                urlLabel: 'ezugi',
                label: "EZUGI",
                image: CasinoEzugiCASINO,
                mobileBgClass: 'ezugi-bg',
                logo: CasinoEzugiLogo,
                logoAlt: CasinoEzugiLogo,
                url: "EZUGI",
                hasUrl: true,
                gameCode: "",
                providerId: 'EZUGI', //for maintenance checking,
                specialTag: '',
                currency: ['THB', 'SGD'],
                landingBanner: casinoLandingBannerEzugi,
                landingBannerClassName: 'landing-banner-ezugi',
                landingBannerDesc: 'LANDING_BANNER_DESC_EZUGI',
                landingThumbnail: casinoLandingThumbnailEzugi,
                floatingImg: [casinoLandingFloatingImg1, casinoLandingFloatingImg2]
            },
            {
                id: "casinopp",
                urlLabel: 'pragmatic-play',
                label: "PRAGMATICPLAY",
                image: CasinoPPCASINO,
                mobileBgClass: 'pp-casino-bg',
                logo: CasinoPPLogo,
                logoAlt: CasinoPPLogo,
                url: "PRAGMATICPLAYLIVECASINO",
                hasUrl: true,
                gameCode: "",
                providerId: 'PRAGMATICPLAYLIVECASINO', //for maintenance checking,
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingBanner: casinoLandingBannerPP,
                landingBannerClassName: 'landing-banner-pp',
                landingBannerDesc: 'LANDING_BANNER_DESC_PP',
                landingThumbnail: casinoLandingThumbnailPP,
                floatingImg: [casinoLandingFloatingImg1, casinoLandingFloatingImg2]
            },
            {
                id: "casinosexy",
                urlLabel: 'sexy-bacarrat',
                label: "SEXY",
                image: CasinoSexy,
                mobileBgClass: 'sexy-bg',
                logo: CasinoSexyLogo,
                logoAlt: CasinoSexyLogo,
                //url: "/home/live/sexy-gaming",
                url: "SEXYBACARRAT",
                hasUrl: true,
                gameCode: "",
                providerId: 'SEXYBACARRAT', //for maintenance checking,
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingBanner: casinoLandingBannerSexy,
                landingBannerClassName: 'landing-banner-sexy',
                landingBannerDesc: 'LANDING_BANNER_DESC_SEXY',
                landingThumbnail: casinoLandingThumbnailSexy,
                floatingImg: [casinoLandingFloatingImg1, casinoLandingFloatingImg2]
            },
            {
                id: "casinosa",
                urlLabel: 'sa-gaming',
                label: "SA GAMING",
                image: CasinoSA,
                logoAlt: CasinoSAAlt,
                mobileBgClass: 'sa-bg',
                logo: CasinoSALogo,
                url: "SAGAMING",
                hasUrl: true,
                gameCode: "",
                providerId: 'SAGAMING', //for maintenance checking,
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingBanner: casinoLandingBannerSA,
                landingBannerClassName: 'landing-banner-sa',
                landingBannerDesc: 'LANDING_BANNER_DESC_SA',
                landingThumbnail: casinoLandingThumbnailSA,
                floatingImg: [casinoLandingFloatingImg1, casinoLandingFloatingImg2]
            },
            {
                id: "casinoopus",
                urlLabel: 'opus',
                label: "OPUS LIVE CASINO",
                image: CasinoOPUS,
                mobileBgClass: 'opus-bg',
                logo: CasinoOPUSWhiteLogo,
                logoAlt: CasinoOPUSLogo,
                url: "OPUSLIVECASINO",
                hasUrl: true,
                gameCode: "",
                providerId: 'OPUSLIVECASINO', //for maintenance checking,
                specialTag: '',
                currency: ['THB'],
                landingBanner: casinoLandingBannerOpus,
                landingBannerClassName: 'landing-banner-opus',
                landingBannerDesc: 'LANDING_BANNER_DESC_OPUS',
                landingThumbnail: casinoLandingThumbnailOpus,
                floatingImg: [casinoLandingFloatingImg1, casinoLandingFloatingImg2]
            },
            {
                id: "casinoyeebet",
                urlLabel: 'yeebet',
                label: "YEEBET",
                image: CasinoYEEBET,
                mobileBgClass: 'yeebet-bg',
                logo: CasinoYEEBETLogo,
                logoAlt: CasinoYEEBETLogo,
                url: "YEEBETLIVECASINO",
                hasUrl: true,
                gameCode: "",
                providerId: 'YEEBET', //for maintenance checking,
                specialTag: '',
                currency: ['THB', 'SGD'],
                landingBanner: casinoLandingBannerYeeBet,
                landingBannerClassName: 'landing-banner-yb',
                landingBannerDesc: 'LANDING_BANNER_DESC_YB',
                landingThumbnail: casinoLandingThumbnailYB,
                floatingImg: [casinoLandingFloatingImg1, casinoLandingFloatingImg2]
            },
            {
                id: "casinowm",
                urlLabel: 'wm-casino',
                label: "WM",
                image: CasinoVMCASINO,
                mobileBgClass: 'wm-bg',
                logo: CasinoVMCASINOLogo,
                logoAlt: CasinoVMCASINOLogo,
                url: "WMLIVECASINO",
                hasUrl: true,
                gameCode: "",
                providerId: 'WM', //for maintenance checking,
                specialTag: '',
                currency: ['THB', 'SGD'],
                landingBanner: casinoLandingBannerWM,
                landingBannerClassName: 'landing-banner-wm',
                landingBannerDesc: 'LANDING_BANNER_DESC_WM',
                landingThumbnail: casinoLandingThumbnailWM,
                floatingImg: [casinoLandingFloatingImg1, casinoLandingFloatingImg2]
            },
            {
                id: "casinobeter",
                urlLabel: 'beter',
                label: "BETER",
                image: CasinoBeter,
                mobileBgClass: 'beter-bg',
                logo: CasinoBeterLogo,
                logoAlt: CasinoBeterLogo,
                url: "BETERLIVE",
                hasUrl: true,
                gameCode: "",
                providerId: 'BETER', //for maintenance checking,
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingBanner: casinoLandingBannerBeter,
                landingBannerClassName: 'landing-banner-beter',
                landingBannerDesc: 'LANDING_BANNER_DESC_BETER',
                landingThumbnail: casinoLandingThumbnailBeter,
                floatingImg: [casinoLandingFloatingImg1, casinoLandingFloatingImg2]
            },
            {
                id: "casinobbin",
                urlLabel: 'bbin',
                label: "BBIN",
                image: CasinoBbin,
                mobileBgClass: 'bbin-bg',
                logo: CasinoBbinLogo,
                logoAlt: CasinoBbinLogo,
                url: "BBIN",
                hasUrl: true,
                gameCode: "",
                providerId: 'BBIN', //for maintenance checking,
                specialTag: '',
                currency: ['SGD'],
                landingBanner: casinoLandingBannerBbin,
                landingBannerClassName: 'landing-banner-bbin',
                landingBannerDesc: 'LANDING_BANNER_DESC_BBIN',
                landingThumbnail: casinoLandingThumbnailBbin,
                floatingImg: [casinoLandingFloatingImg1, casinoLandingFloatingImg2]
            },
            // {
            //     id: "casinoroyal",
            //     urlLabel: 'royal-gaming',
            //     label: "ROYAL",
            //     image: CasinoROYALCASINO,
            //     mobileBgClass: 'royal-bg',
            //     logo: CasinoROYALLogo,
            //     logoAlt: CasinoROYALLogo,
            //     url: "ROYAL",
            //     hasUrl: true,
            //     gameCode: "",
            //     providerId: 'ROYAL', //for maintenance checking,
            //     specialTag: '',
            //     currency: ['THB', 'SGD'],
            //     landingBanner: casinoLandingBannerRoyal,
            //     landingBannerClassName: 'landing-banner-royal',
            //     landingBannerDesc: 'LANDING_BANNER_DESC_ROYAL',
            //     landingThumbnail: casinoLandingThumbnailRoyal,
            //     floatingImg: [casinoLandingFloatingImg1, casinoLandingFloatingImg2]
            // },
        ],
    },
    {
        gameType: "slots",
        label: 'MENU_SLOTS',
        landingPageBanner: SlotsLandingPageBanner,
        homeCardImg: SlotsCardImg,
        mobileMenuImg: MobileGameMenuSlots,
        mobileMenuImgSelected: MobileGameMenuSlotsSelected,
        mobileGameListType: 2,
        currency: ['THB', 'SGD', 'PHP'],
        games: [
            {
                id: "slotspg",
                label: "PG",
                image: SlotsPG,
                mobileBgClass: 'pg-bg',
                logo: SlotsPGLogo,
                logoAlt: SlotsPGLogo,
                url: "slots/pg",
                hasUrl: false,

                type: 'slots',
                providerId: 'PGSLOT',
                name: 'PGSLOT',
                imgUrl: SlotsPGBlackLogo,
                gameCode: "",
                specialTag: '',
                currency: ['THB'],
                landingIcon: landingIconPG,
                requireVpn: false,
                isNew: false
            },
            {
                id: "slotspp",
                label: "Pragmatic Play",
                image: SlotsPP,
                mobileBgClass: 'pp-bg',
                logo: SlotsPPLogo,
                logoAlt: SlotsPPLogo,
                // url: "",
                url: "slots/pragmatic-play",
                hasUrl: false,

                type: 'slots',
                providerId: 'PRAGMATICPLAY',
                name: 'Pragmatic Play',
                imgUrl: SlotsPPImg,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingIcon: landingIconPP,
                requireVpn: true,
                isNew: false
            },
            {
                id: "slotssg",
                label: "Spade Gaming",
                image: SlotsSG,
                mobileBgClass: 'spade-bg',
                logo: SlotsSGLogo,
                logoAlt: SlotsSGLogo,
                url: "slots/spade-gaming", //for api calling or redirection
                hasUrl: false,

                type: 'slots',
                providerId: 'SPADEGAMING', //for game list page menu selection
                name: "Spade Gaming",
                imgUrl: SlotsSGImg,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingIcon: landingIconSG,
                requireVpn: false,
                isNew: false
            },
            {
                id: "slotsjoker",
                label: "Joker",
                image: SlotsJoker,
                mobileBgClass: 'joker-bg',
                logo: SlotsJokerLogo,
                logoAlt: SlotsJokerLogo,
                url: "slots/joker",
                // url: "",
                hasUrl: false,

                type: 'slots',
                providerId: 'JOKER',
                name: "Joker",
                imgUrl: SlotsJokerImg,
                gameCode: "",
                specialTag: 'HOT',
                currency: ['THB', 'PHP'],
                landingIcon: landingIconJoker,
                requireVpn: true,
                isNew: false
            },
            {
                id: "slotscq9",
                label: "CQ9",
                image: SlotsCQ9,
                mobileBgClass: 'cqnine-bg',
                logo: SlotsCQ9Logo,
                logoAlt: SlotsCQ9Logo,
                url: "slots/cq9",
                hasUrl: false,

                type: 'slots',
                providerId: 'CQ9',
                name: "CQ9",
                imgUrl: SlotsCQ9Img,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingIcon: landingIconCQ9,
                requireVpn: false,
                isNew: false
            },
            {
                id: "slotjili",
                label: "Jili",
                image: SlotsJL,
                mobileBgClass: 'jl-bg',
                logo: SlotsJLLogo,
                logoAlt: SlotsJLLogo,
                url: "slots/jili",
                hasUrl: false,

                type: 'slots',
                providerId: 'JILI', //for game list page menu selection
                name: "Jili",
                imgUrl: SlotsJLLogo,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingIcon: landingIconJili,
                requireVpn: false,
                isNew: false
            },
            {
                id: "slotfast",
                label: "FastSpin",
                image: SlotsFastSpin,
                mobileBgClass: 'fs-bg',
                logo: SlotsFastSpinLogo,
                logoAlt: SlotsFastSpinLogo,
                url: "slots/fast-spin",
                hasUrl: false,

                type: 'slots',
                providerId: 'FASTSPIN', //for game list page menu selection
                name: "FastSpin",
                imgUrl: SlotsFastSpinLogo,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingIcon: landingIconFastSpin,
                requireVpn: false,
                isNew: true
            },
            // {
            //     id: "slotspribe",
            //     label: "Spribe",
            //     image: SlotsSpribe,
            //     mobileBgClass: 'spribe-bg',
            //     logo: SlotsSpribeLogo,
            //     logoAlt: SlotsSpribeLogo,
            //     url: "slots/spribe",
            //     hasUrl: false,

            //     type: 'slots',
            //     providerId: 'SPRIBE', //for game list page menu selection
            //     name: "Spribe",
            //     imgUrl: SlotsSpribeLogo,
            //     gameCode: "",
            //     specialTag: '',
            //     currency: ['THB', 'SGD', 'PHP'],
            //     landingIcon: landingIconSpribe,
            //     requireVpn: false,
            //     isNew: true
            // },
            {
                id: "slotfacai",
                label: "Fa Chai",
                image: SlotsFacai,
                mobileBgClass: 'facai-bg',
                logo: SlotsFacaiLogo,
                logoAlt: SlotsFacaiLogo,
                url: "slots/facai",
                hasUrl: false,

                type: 'slots',
                providerId: 'FACAI', //for game list page menu selection
                name: "Facai",
                imgUrl: SlotsFacaiLogo,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingIcon: landingIconFacai,
                requireVpn: false,
                isNew: true
            },
            {
                id: "slotnetent",
                label: "Netent",
                image: SlotsNetent,
                mobileBgClass: 'netent-bg',
                logo: SlotsNetentLogo,
                logoAlt: SlotsNetentLogo,
                url: "slots/netent",
                hasUrl: false,

                type: 'slots',
                providerId: 'NETENT', //for game list page menu selection
                name: "Netent",
                imgUrl: SlotsNetentLogo,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingIcon: landingIconNetent,
                requireVpn: false,
                isNew: true
            },
            {
                id: "slotnolimitcity",
                label: "NoLimitCity",
                image: SlotsNoLimit,
                mobileBgClass: 'nolimit-bg',
                logo: SlotsNoLimitLogo,
                logoAlt: SlotsNoLimitLogo,
                url: "slots/nolimitcity",
                hasUrl: false,

                type: 'slots',
                providerId: 'NOLIMITCITY', //for game list page menu selection
                name: "NoLimitcity",
                imgUrl: SlotsNoLimitLogo,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingIcon: landingIconNoLimit,
                requireVpn: false,
                isNew: true
            },
            {
                id: "slotredtiger",
                label: "RedTiger",
                image: SlotsRedTiger,
                mobileBgClass: 'redtiger-bg',
                logo: SlotsRedTigerLogo,
                logoAlt: SlotsRedTigerLogo,
                url: "slots/redtiger",
                hasUrl: false,

                type: 'slots',
                providerId: 'REDTIGER', //for game list page menu selection
                name: "RedTIger",
                imgUrl: SlotsRedTigerLogo,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingIcon: landingIconRedTiger,
                requireVpn: false,
                isNew: true
            },
            {
                id: "slotbigtimegaming",
                label: "BigTimeGaming",
                image: SlotsBigTimeGaming,
                mobileBgClass: 'bigtimegaming-bg',
                logo: SlotsBigTimeGamingLogo,
                logoAlt: SlotsBigTimeGamingLogo,
                url: "slots/bigtimegaming",
                hasUrl: false,

                type: 'slots',
                providerId: 'BIGTIMEGAMING', //for game list page menu selection
                name: "BigTimeGaming",
                imgUrl: SlotsBigTimeGamingLogo,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingIcon: landingIconBigTimeGaming,
                requireVpn: false,
                isNew: true
            },
            // {
            //     id: "slotsmgs",
            //     label: "MGS",
            //     image: SlotsMGS,
            //     logo: SlotsMGSLogo,
            //     //url: "slots/MGS",
            //     url: "",
            //     hasUrl: false,

            //     type: 'slots',
            //     providerId: 'MGS',
            //     name: 'MGS',
            //     imgUrl: SlotsMGSImg,
            //     gameCode: "",
            //     specialTag: ''
            // },
            // {
            //     id: "slotsdg",
            //     label: "DRAGON GAMING",
            //     image: SlotsDG,
            //     logo: SlotsDGLogo,
            //     url: "",
            //     //url: "home/slots/DRAGONGAMING",
            //     hasUrl: false,

            //     type: 'slots',
            //     providerId: 'DRAGONGAMING',
            //     name: "DRAGON GAMING",
            //     imgUrl: SlotsDGImg,
            //     gameCode: "",
            //     specialTag: ''
            // },
            // {
            //     id: "slotmega",
            //     label: "MEGA",
            //     image: SlotsMega,
            //     logo: SlotsMegaLogo,
            //     url: "",
            //     hasUrl: false,

            //     type: 'slots',
            //     providerId: 'MEGA', //for game list page menu selection
            //     name: "Mega",
            //     imgUrl: SlotsMegaLogo,
            //     gameCode: "",
            //     specialTag: ''
            // },
        ],
    },
    {
        gameType: "esports",
        label: 'MENU_ESPORTS',
        landingPageBanner: ESportsLandingPageBanner,
        homeCardImg: EsportsCardImg,
        mobileMenuImg: MobileGameMenuESports,
        mobileMenuImgSelected: MobileGameMenuESportsSelected,
        mobileGameListType: 1,
        currency: ['THB', 'SGD', 'PHP'],
        games: [
            {
                id: "esportscmd",
                urlLabel: 'tf-gaming',
                label: "TF GAMING",
                image: ESportsTFG,
                mobileBgClass: 'tf-bg',
                logo: ESportsTFGLogo,
                logoAlt: ESportsTFGLogo,
                url: "TFGAMING",
                hasUrl: true,
                gameCode: "",
                providerId: 'TFGAMING', //for maintenance checking,
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingBanner: esportsLandingBannerTF,
                landingBannerClassName: 'landing-banner-tf',
                landingBannerDesc: 'LANDING_BANNER_DESC_TF',
                landingThumbnail: esportsLandingThumbnailTF,
                floatingImg: [esportLandingFloatingImg1, esportLandingFloatingImg2]
            },
            // {
            //     id: "esportssaba",
            //     label: "IM",
            //     image: ESportsIM,
            //     logo: ESportsIMLogo,
            //     url: "",
            //     hasUrl: true,
            //     gameCode: "",
            //     providerId: 'SABA', //for maintenance checking,
            //     specialTag: ''
            // },
        ],
    },
];

export const gamesProviderRight = [
    {
        gameType: "fishing",
        label: 'MENU_FISHING',
        landingPageBanner: FishingLandingPageBanner,
        homeCardImg: '',
        mobileMenuImg: MobileGameMenuFish,
        mobileMenuImgSelected: MobileGameMenuFishSelected,
        mobileGameListType: 1,
        currency: ['THB', 'SGD', 'PHP'],
        games: [
            {
                id: "sfishingsg",
                label: "SPADE GAMING",
                image: FishSGLogo,
                mobileBgClass: 'sg-bg',
                logo: SlotsSGLogo,
                logoAlt: SlotsSGLogo,
                url: "fishing/spade-gaming", //for api calling or redirection
                hasUrl: false,

                type: 'fishing',
                providerId: 'SPADEGAMING', //for game list page menu selection
                name: "Spade Gaming",
                imgUrl: SlotsSGImg,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingIcon: landingIconSG,
                requireVpn: false
            },
            // {
            //     id: "fishingjoker",
            //     label: "JOKER",
            //     image: FishJOKERLogo,
            //     mobileBgClass: 'fish-joker-bg',
            //     logo: SlotsJokerLogo,
            //     logoAlt: SlotsJokerLogo,
            //     url: "fishing/joker",
            //     // url: "",
            //     hasUrl: false,

            //     type: 'fishing',
            //     providerId: 'JOKER',
            //     name: "Joker",
            //     imgUrl: SlotsJokerImg,
            //     gameCode: "",
            //     specialTag: 'HOT',
            //     currency: ['THB', 'PHP'],
            //     landingIcon: landingIconJoker,
            //     requireVpn: false
            // },
            {
                id: "fishingcq",
                label: "CQ9 FISHING",
                image: FishingCQ9,
                mobileBgClass: 'fish-cqnine-bg',
                logo: SlotsCQ9Logo,
                logoAlt: SlotsCQ9Logo,
                url: "fishing/cq9",
                hasUrl: false,

                type: 'fishing',
                providerId: 'CQ9', //for game list page menu selection
                name: "CQ9",
                imgUrl: SlotsCQ9Logo,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingIcon: landingIconCQ9,
                requireVpn: false
            },
            {
                id: "fishingjili",
                label: "JILI",
                image: FishJLLogo,
                mobileBgClass: 'fish-jl-bg',
                logo: SlotsJLLogo,
                logoAlt: SlotsJLLogo,
                url: "fishing/jili",
                hasUrl: false,

                type: 'fishing',
                providerId: 'JILI', //for game list page menu selection
                name: "Jili",
                imgUrl: SlotsJLLogo,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingIcon: landingIconJili,
                requireVpn: false
            },
            // {
            //     id: "fishingbb",
            //     label: "BG FISHING",
            //     image: FishingBG,
            //     logo: CasinoBGLogo,
            //     url: "",
            //     hasUrl: false,

            //     type: 'fishing',
            //     providerId: 'BG', //for game list page menu selection
            //     name: "BG Fishing",
            //     imgUrl: CasinoBGLogo,
            //     gameCode: "",
            //     specialTag: ''
            // },
            // {
            //     id: "fishingpp",
            //     label: "PRAGMATIC PLAY",
            //     image: FishingPP,
            //     logo: SlotsPPLogo,
            //     url: "fishing/PRAGMATICPLAY",
            //     hasUrl: false,

            //     type: 'fishing',
            //     providerId: 'PRAGMATICPLAY', //for game list page menu selection
            //     name: "Pragmatic Play",
            //     imgUrl: SlotsPPLogo,
            //     gameCode: "",
            //     specialTag: ''
            // },
        ],
    },
    {
        gameType: "instant-win",
        label: 'MENU_INSTANT_WIN',
        landingPageBanner: InstantWinLandingPageBanner,
        homeCardImg: InstantWinCardImg,
        mobileMenuImg: MobileGameMenuInstantWin,
        mobileMenuImgSelected: MobileGameMenuInstantWinSelected,
        mobileGameListType: 2,
        currency: ['THB', 'SGD', 'PHP'],
        games: [
            {
                id: "slotspribe",
                label: "Spribe",
                image: SlotsSpribe,
                mobileBgClass: 'spribe-bg',
                logo: SlotsSpribeLogo,
                logoAlt: SlotsSpribeLogo,
                url: "instant-win/spribe",
                hasUrl: false,

                type: 'slots',
                providerId: 'SPRIBE', //for game list page menu selection
                name: "Spribe",
                imgUrl: SlotsSpribeLogo,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingIcon: landingIconSpribe,
                requireVpn: false,
                isNew: true
            },
            {
                id: "slotti",
                label: "T1 Games",
                image: SlotsT1,
                mobileBgClass: 't1-bg',
                logo: SlotsT1Logo,
                logoAlt: SlotsT1Logo,
                url: "instant-win/t1",
                hasUrl: false,

                type: 'slots',
                providerId: 'T1', //for game list page menu selection
                name: "T1 Games",
                imgUrl: SlotsT1Logo,
                gameCode: "",
                specialTag: '',
                currency: ['THB', 'PHP'],
                landingIcon: landingIconT1,
                requireVpn: false,
                isNew: true
            },
        ],
    },
    {
        gameType: "virtual",
        label: 'MENU_VIRTUAL_GAMES',
        landingPageBanner: VGamesLandingPageBanner,
        homeCardImg: VirtualGamesCardImg,
        mobileMenuImg: MobileGameMenuVG,
        mobileMenuImgSelected: MobileGameMenuVGSelected,
        mobileGameListType: 1,
        currency: ['THB', 'SGD', 'PHP'],
        games: [
            {
                id: "virtualkm",
                urlLabel: 'king-maker',
                label: "KINGMAKER",
                image: MenuPP,
                mobileBgClass: 'km-bg',
                logo: MenuPPLogo,
                logoAlt: MenuPPLogo,
                url: "QUEENMAKER",
                hasUrl: true,
                gameCode: "",
                providerId: 'QUEENMAKER', //for maintenance checking,
                specialTag: '',
                currency: ['THB', 'PHP'],
                landingBanner: virtualLandingBannerKm,
                landingBannerClassName: 'landing-banner-virtualkm',
                landingBannerDesc: 'LANDING_BANNER_DESC_VIRTUALKM',
                landingThumbnail: virtualLandingThumbnailKm,
                floatingImg: [virtualLandingFloatingImg1, virtualLandingFloatingImg2]
            },
            {
                id: "virtualmega",
                urlLabel: 'millionaire-poker',
                label: "MEGA888",
                image: MenuMP,
                mobileBgClass: 'mega-bg',
                logo: MenuMPLogo,
                logoAlt: MenuMPLogo,
                url: "MPOKER",
                hasUrl: true,
                gameCode: "",
                providerId: 'MPOKER', //for maintenance checking,
                specialTag: '',
                currency: ['THB', 'SGD', 'PHP'],
                landingBanner: virtualLandingBannerMp,
                landingBannerClassName: 'landing-banner-virtualmp',
                landingBannerDesc: 'LANDING_BANNER_DESC_VIRTUALMP',
                landingThumbnail: virtualLandingThumbnailMp,
                floatingImg: [virtualLandingFloatingImg1, virtualLandingFloatingImg2]
            },
            // {
            //     id: "virtualmp",
            //     label: "MP",
            //     image: MenuMega,
            //     logo: MenuMegaLogo,
            //     url: "",
            //     hasUrl: true,
            //     gameCode: ""
            // },
        ],
    },
    {
        gameType: "lottery",
        label: 'MENU_LOTTERY',
        landingPageBanner: LotteryLandingPageBanner,
        homeCardImg: LotteryCardImg,
        mobileMenuImg: MobileGameMenuLottery,
        mobileMenuImgSelected: MobileGameMenuLotterySelected,
        mobileGameListType: 1,
        currency: ['THB'],
        games: [
            {
                id: "lottokm",
                urlLabel: 'qq-keno',
                label: "QQ KENO",
                image: LotteryQQKeno,
                mobileBgClass: 'lotto-km-bg',
                logo: LotteryQQKenoLogo,
                logoAlt: LotteryQQKenoLogo,
                mobileLogo: LotteryQQKenoMobileLogo,
                url: "QQLOTTERY",
                hasUrl: true,
                gameCode: "keno",
                providerId: 'QQLOTTERY', //for maintenance checking,
                specialTag: '',
                currency: ['THB'],
                landingBanner: lottoLandingBannerKm,
                landingBannerClassName: 'landing-banner-km',
                landingBannerDesc: 'LANDING_BANNER_DESC_KM',
                landingThumbnail: lottoLandingThumbnailKm,
                floatingImg: [lottoLandingFloatingImg1, lottoLandingFloatingImg2]
            },
            {
                id: "lottoqq",
                urlLabel: 'qq-lottery',
                label: "QQ LOTTERY",
                image: LotteryQQLottery,
                mobileBgClass: 'lotto-qq-bg',
                logo: LotteryQQLotteryLogo,
                logoAlt: LotteryQQLotteryLogo,
                mobileLogo: LotteryQQLotteryMobileLogo,
                url: "QQLOTTERY",
                hasUrl: true,
                gameCode: "qqthai",
                providerId: 'QQLOTTERY', //for maintenance checking,
                currency: ['THB'],
                landingBanner: lottoLandingBannerQq,
                landingBannerClassName: 'landing-banner-qq',
                landingBannerDesc: 'LANDING_BANNER_DESC_QQ',
                landingThumbnail: lottoLandingThumbnailQq,
                floatingImg: [lottoLandingFloatingImg1, lottoLandingFloatingImg2]
            },
            // {
            //     id: "lottoae",
            //     label: "AE LOTTERY",
            //     image: LotteryAELottery,
            //     logo: LotteryAELotteryLogo,
            //     url: "",
            //     hasUrl: true,
            //     gameCode: "",
            //     providerId: 'AELOTTERY', //for maintenance checking,
            //     specialTag: ''
            // },
        ],
    },
    {
        gameType: "horseracing",
        label: 'MENU_HORSE_RACING',
        landingPageBanner: LotteryLandingPageBanner,
        homeCardImg: LotteryCardImg,
        mobileMenuImg: MobileGameMenuHorse,
        mobileMenuImgSelected: MobileGameMenuHorseSelected,
        mobileGameListType: 1,
        currency: ['SGD'],
        games: [
            {
                id: "RCB888",
                urlLabel: 'rcb888',
                label: "RCB888",
                image: HorseBook,
                mobileBgClass: 'horse-rc-bg',
                logo: HorseBookLogo,
                logoAlt: HorseBookLogo,
                url: "RCB888",
                hasUrl: true,
                gameCode: "rcb888",
                providerId: 'RCB888', //for maintenance checking,
                specialTag: '',
                currency: ['SGD'],
                landingBanner: horseLandingBanner,
                landingBannerClassName: 'landing-banner-rcb',
                landingBannerDesc: 'LANDING_BANNER_DESC_RCB',
                landingThumbnail: horseLandingThumbnail,
                floatingImg: [horseLandingFloatingImg1, horseLandingFloatingImg2]
            },
        ],
    },
    {
        gameType: "cockfighting",
        label: 'MENU_COCKFIGHTING',
        landingPageBanner: LotteryLandingPageBanner,
        homeCardImg: LotteryCardImg,
        mobileMenuImg: MobileGameMenuCockFight,
        mobileMenuImgSelected: MobileGameMenuCockFightSelected,
        mobileGameListType: 1,
        currency: ['THB', 'PHP'],
        games: [
            {
                id: "GA28",
                urlLabel: 'ga28',
                label: "GA28",
                image: GA28,
                mobileBgClass: 'cockfight-ga28-bg',
                logo: GA28Logo,
                mobileLogo: GA28MobileLogo,
                logoAlt: GA28Logo,
                url: "GA28",
                hasUrl: true,
                gameCode: "ga28",
                providerId: 'GA28', //for maintenance checking,
                specialTag: '',
                currency: ['THB', 'PHP'],
                landingBanner: ga28LandingBanner,
                landingBannerClassName: 'landing-banner-ga28',
                landingBannerDesc: 'LANDING_BANNER_DESC_GA28',
                landingThumbnail: ga28Thumbnail,
                floatingImg: [ga28LandingFloatingImg1, ga28LandingFloatingImg2]
            },
        ],
    },
];

export const hotGameList = [
    {
        id: '1',
        imgUrl: HotGames,
        gameCode: 'PG|29|cai-shen-wins',
        providerId: 'PGSLOT'
    },
    {
        id: '2',
        imgUrl: HotGames2,
        gameCode: 'PG|29|ways-of-qilin',
        providerId: 'PGSLOT'
    },
    {
        id: '3',
        imgUrl: HotGames3,
        gameCode: 'PG|29|ganesha-fortune',
        providerId: 'PGSLOT'
    }
]

export const faqList = [
    {
        sectionTitle: 'FAQ_SECTION_GENERAL',
        hasSubCategory: false,
        subCategory: [],
        panels: [
            {
                key: '1-1',
                isInnerHtml: true,
                panelTitle: 'FAQ_SECTION_GENERAL_SUBTITLE_1',
                panelContent: 'FAQ_SECTION_GENERAL_ANSWER_1'
            },
            {
                key: '1-2',
                isInnerHtml: true,
                currencySpecificContent: true,
                panelTitle: 'FAQ_SECTION_GENERAL_SUBTITLE_2',
                panelContent: 'FAQ_SECTION_GENERAL_ANSWER_2'
            },
            {
                key: '1-3',
                isInnerHtml: true,
                panelTitle: 'FAQ_SECTION_GENERAL_SUBTITLE_3',
                panelContent: 'FAQ_SECTION_GENERAL_ANSWER_3'
            }
        ]
    },
    {
        sectionTitle: 'FAQ_SECTION_PAYMENT',
        hasSubCategory: true,
        subCategory: [
            {
                subCategoryTitle: "FAQ_SECTION_PAYMENT_SUBCATEGORY_1",
                panels: [
                    {
                        key: '2-1_1',
                        isInnerHtml: true,
                        currencySpecificContent: true,
                        panelTitle: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_SUBTITLE_1_1',
                        panelContent: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_ANSWER_1_1'
                    },
                    {
                        key: '2-1-2',
                        isInnerHtml: true,
                        panelTitle: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_SUBTITLE_1_2',
                        panelContent: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_ANSWER_1_2'
                    },
                    {
                        key: '2-1-3',
                        isInnerHtml: true,
                        panelTitle: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_SUBTITLE_1_3',
                        panelContent: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_ANSWER_1_3'
                    },
                ]
            },
            {
                subCategoryTitle: "FAQ_SECTION_PAYMENT_SUBCATEGORY_2",
                panels: [
                    {
                        key: '2-2-1',
                        isInnerHtml: true,
                        panelTitle: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_SUBTITLE_2_1',
                        panelContent: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_ANSWER_2_1'
                    },
                    {
                        key: '2-2-2',
                        isInnerHtml: false,
                        panelTitle: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_SUBTITLE_2_2',
                        panelContent: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_ANSWER_2_2'
                    },
                    {
                        key: '2-2-3',
                        isInnerHtml: false,
                        panelTitle: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_SUBTITLE_2_3',
                        panelContent: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_ANSWER_2_3'
                    },
                    {
                        key: '2-2-4',
                        isInnerHtml: true,
                        panelTitle: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_SUBTITLE_2_4',
                        panelContent: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_ANSWER_2_4'
                    },
                    {
                        key: '2-2-5',
                        isInnerHtml: true,
                        panelTitle: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_SUBTITLE_2_5',
                        panelContent: 'FAQ_SECTION_PAYMENT_SUBCATEGORY_ANSWER_2_5'
                    },
                ]
            }
        ],
    },
    {
        sectionTitle: 'FAQ_SECTION_REBATE',
        hasSubCategory: false,
        subCategory: [],
        panels: [
            {
                key: '3-1',
                isInnerHtml: true,
                panelTitle: 'FAQ_SECTION_REBATE_SUBTITLE_1',
                panelContent: 'FAQ_SECTION_REBATE_ANSWER_1'
            },
            {
                key: '3-2',
                isInnerHtml: true,
                panelTitle: 'FAQ_SECTION_REBATE_SUBTITLE_2',
                panelContent: 'FAQ_SECTION_REBATE_ANSWER_2'
            },
            {
                key: '3-3',
                isInnerHtml: true,
                panelTitle: 'FAQ_SECTION_REBATE_SUBTITLE_3',
                panelContent: 'FAQ_SECTION_REBATE_ANSWER_3'
            },
            {
                key: '3-4',
                isInnerHtml: true,
                panelTitle: 'FAQ_SECTION_REBATE_SUBTITLE_4',
                panelContent: 'FAQ_SECTION_REBATE_ANSWER_4'
            },
            {
                key: '3-5',
                isInnerHtml: true,
                panelTitle: 'FAQ_SECTION_REBATE_SUBTITLE_5',
                panelContent: 'FAQ_SECTION_REBATE_ANSWER_5'
            },
        ]
    },
    {
        sectionTitle: 'FAQ_SECTION_KYC',
        hasSubCategory: false,
        subCategory: [],
        panels: [
            {
                key: '4-1',
                isInnerHtml: true,
                panelTitle: 'FAQ_SECTION_KYC_SUBTITLE_1',
                panelContent: 'FAQ_SECTION_KYC_ANSWER_1'
            },
            {
                key: '4-2',
                isInnerHtml: true,
                panelTitle: 'FAQ_SECTION_KYC_SUBTITLE_2',
                panelContent: 'FAQ_SECTION_KYC_ANSWER_2'
            },
            {
                key: '4-3',
                isInnerHtml: true,
                panelTitle: 'FAQ_SECTION_KYC_SUBTITLE_3',
                panelContent: 'FAQ_SECTION_KYC_ANSWER_3'
            }
        ]
    },
    {
        sectionTitle: 'FAQ_SECTION_OTHER',
        hasSubCategory: false,
        subCategory: [],
        panels: [
            {
                key: '5-1',
                isInnerHtml: false,
                panelTitle: 'FAQ_SECTION_OTHER_SUBTITLE_1',
                panelContent: 'FAQ_SECTION_OTHER_ANSWER_1'
            },
            {
                key: '5-2',
                isInnerHtml: true,
                currencySpecificContent: true,
                panelTitle: 'FAQ_SECTION_OTHER_SUBTITLE_2',
                panelContent: 'FAQ_SECTION_OTHER_ANSWER_2'
            },
        ]
    },
]

export const regionLanguageList = [
    {
        id: 'TH',
        currency: 'THB',
        name: 'Thailand',
        icon: CountryThai,
        language: ['en', 'th']
    },
    {
        id: 'SG',
        currency: 'SGD',
        name: 'Singapore',
        icon: CountrySg,
        language: ['en', 'zh-CN']
    },
    {
        id: 'PH',
        currency: 'PHP',
        name: 'Philippines',
        icon: CountryPh,
        language: ['en']
    }
]

export const announcements = {
    "th": "IB8 นำเสนอผลิตภัณฑ์เกมคุณภาพสูงและโบนัสมากมายแก่ผู้เล่นของเรา ทีมสนับสนุนลูกค้าของเราพร้อมที่จะช่วยเหลือผู้เล่นตลอด 24 ชั่วโมงทุกวัน พันธมิตรเกมที่เชื่อถือได้ในเอเชีย สร้างบัญชีฟรีของคุณและเริ่มเล่นเกมกับ IB8!",
    "en": "IB8 offers our players the highest quality gaming products and plenty of bonuses. Our customer support team is ready to assist players 24/7. Asia trusted gaming partners. Create your free account and start gaming with IB8!",
    "zh-CN": "IB8 为玩家提供最优质的游戏产品和大量豐富的奖金以及奖品。我们的客服团队随时准备为玩家提供 24/7 全天候协助。亚洲值得信赖的游戏合作伙伴。領取 IB8 免費帐户并开始使用 IB8 游戏"
}